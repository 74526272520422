import { useEffect, useState } from "react"
import { ISettingsItem, useSettings } from "../../Settings"
import Loading from "../../components/Loading"
import { GetStaticContent } from "../../api/assets"
import { Grid } from '@mui/material'
import {
  Heading,
  Slogan,
  BackImage,
  Details,
  UncomplicateButton,
  CategoryChoice,
  Timer,
  ChoosePlanText,
  SelectPlan,
  SelectCategory,
  ActivatedBnfts,
  PlanDetailsText,
  SelectPlanDetails,
  SliderPlans,
  Heading2,
  PlanConfigText,
  StepperPlan,
  SelectSmartControl,
  Steps,
  SelectChipInfo,
  OrderSummaryText,
  FirstSummary,
  SecondSummary,
  ChosenPlanChip,
  PurchaseSummary,
  SloganPg3,
  Description,
  PlanSummaryPg3,
  StepperConfig,
  AppLink,
  SloganPg4,
} from '../../components'

type SectionProps = {
  id: string
  className: string
  options: ISettingsItem
}

const components: any = {
  div: Grid,
  Heading,
  Slogan,
  BackImage,
  Details,
  UncomplicateButton,
  CategoryChoice,
  Timer,
  ChoosePlanText,
  SelectPlan,
  SelectCategory,
  ActivatedBnfts,
  PlanDetailsText,
  SelectPlanDetails,
  SliderPlans,
  Heading2,
  PlanConfigText,
  StepperPlan,
  SelectSmartControl,
  Steps,
  SelectChipInfo,
  OrderSummaryText,
  FirstSummary,
  SecondSummary,
  ChosenPlanChip,
  PurchaseSummary,
  SloganPg3,
  Description,
  PlanSummaryPg3,
  StepperConfig,
  AppLink,
  SloganPg4,
}

export default function SectionItem({
  id,
  className,
  options: {
    component = undefined,
    file = undefined,
    render = true,
    variables = undefined
  },
}: SectionProps) {
  const [loading, setLoading] = useState(true)
  const [customContent, setContent] = useState<string>()
  const { app } = useSettings()

  useEffect(() => {
    if (app === undefined || file === undefined) {
      setLoading(false)
      return
    }

    const fetchFile = async () => {
      const response = await GetStaticContent({ app, file })
      setContent(response)
      setLoading(false)
    }

    fetchFile()
  }, [app, file])

  if (loading) {
    return <Loading />
  }

  if (customContent) {
    return <div id={id} className={`${className} components`} dangerouslySetInnerHTML={{ __html: customContent }} />
  }

  let Component = components['div']

  if (component !== undefined && Object.keys(components).includes(component)) {
    Component = components[component]
  }

  return render ? (
    <Grid container item id={id} className={`${className} components`}>
      <Component variables={variables} />
    </Grid>
  ) : <></>
}