import { Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import logoDefault from '../../../assets/images/logoDefault2.svg'

export default function Heading2({ name, variables }: ISettingsItem) {
  const navigate = useNavigate()
  const { planIndex } = usePlan()
  const { image = [logoDefault], text = [] } = variables || {}
  const currentLogo = image[planIndex] !== undefined ? image[planIndex] : image[0]

  return (
    <Grid
      item
      className={name ? `${name} heading` : "heading"}
      onClick={() => navigate('/')}
      sx={{ cursor: 'pointer', zIndex: '100' }}
    >
      <img src={currentLogo} alt="Logo" />
      <h3>{text[0]}</h3>
    </Grid>
  )
}