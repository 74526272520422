import { Grid } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { ISettingsItem } from "../../../Settings"
import { usePlan } from "../../../Plans"
import { ReactComponent as LeftArrow } from '../../../assets/icons/leftArrow.svg'

export default function PlanConfigText({ name, variables }: ISettingsItem) {
  const { step, updatePlanState } = usePlan()
  const defaultTextArray1 = ['Configure Seu Plano', 'Escolha mais opções e aprimore seu plano', 'flex']
  const defaultTextArray2 = ['Informações Pessoais', 'Preencha os campos obrigatórios', 'flex']
  const defaultTextArray3 = ['Área de pagamento', 'Aqui você pode pagar pelo seu plano', 'flex']
  const currentDefaultTextArray = step === 0 ? defaultTextArray1 : step === 3 ? defaultTextArray3 : defaultTextArray2
  const { text = currentDefaultTextArray } = variables || {}

  const navigate = useNavigate()
  const handleBack = () => {
    if (step === 0) {
      navigate('/')
    } else if (step > 0) {
      updatePlanState({ step: step - 1 })
    }
  }

  return (
    <Grid item className={name ? `${name} plan-config-text` : "plan-config-text"} xs={12}>
      <div className="iconsArea leftArrow" style={{ display: text[2] }}>
        <LeftArrow onClick={handleBack} />
      </div>
      <div className="textArea">
        <h3>{text[0]}</h3>
        <p>{text[1]}</p>
      </div>
    </Grid>
  )
}