import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from "./api/client"

export interface Plan {
  id: number
  plano: string
  categoria?: string
  categoria_id?: string
  valor: number
  nome_plano: string
  ligacoes: string
  sms: string
  detalhamento: string
  descricao_detalhamento: string
  cor: string
  internet_padrao: string
}

export interface PlansContext {
  plans: Plan[]
  currentPlan: Plan
  planClass: string
  monthlyPrice: number
  annualPrice: number
  chosenPlan: string
  virtualChip: boolean
  portability: boolean
  smartControl: boolean
  planConfigs: boolean
  planBonus: boolean
  planIndex: number
  step: number
  steps: string[]
  loadingPlan: boolean
  currentPurchaseLink: string
  currentSliderValue: number
  updatePlanState: (updatedValues: Partial<PlansContext>) => void
  setvirtualChip: (virtualChip: boolean) => void
  setPortability: (portability: boolean) => void
  setSmartControl: (smartControl: boolean) => void
}

const defaultPlan: Plan = {
  id: 0,
  plano: "",
  valor: 0,
  nome_plano: "",
  ligacoes: "",
  sms: "",
  detalhamento: "",
  descricao_detalhamento: "",
  cor: "",
  internet_padrao: "",
}

const defaultPlansData: PlansContext = {
  plans: [],
  currentPlan: defaultPlan,
  planClass: "plan1",
  monthlyPrice: 39.9,
  annualPrice: 26.6,
  chosenPlan: "",
  virtualChip: false,
  portability: true,
  smartControl: false,
  planConfigs: true,
  planBonus: true,
  planIndex: 0,
  step: 0,
  steps: ["Seu Plano", "Informações", "Endereço", "Pagamento"],
  loadingPlan: true,
  currentPurchaseLink: "",
  currentSliderValue: 0,
  updatePlanState: () => {},
  setvirtualChip: (virtualChip: boolean) => {},
  setPortability: (portability: boolean) => {},
  setSmartControl: (smartControl: boolean) => {}
}

const PlanContext = createContext<PlansContext>(defaultPlansData)

export function usePlan() {
  return useContext(PlanContext)
}

export function PlanProvider({ children }: PropsWithChildren) {
  const [currentState, setCurrentState] = useState<PlansContext>(defaultPlansData)
  const [currentPath, setCurrentPath] = useState<string>()
  const location = useLocation()
  const colorPlanClasses = ["plan1", "plan2", "plan3", "plan4", "plan5"]

  const updatePlanState = (updatedValues: Partial<PlansContext>) => {
    setCurrentState((prevState) => ({
      ...prevState,
      ...updatedValues,
    }))
  }

  const setvirtualChip = useCallback((virtualChip: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      virtualChip: virtualChip,
      planConfigs: virtualChip || prevState.portability || prevState.smartControl
    }))
  }, [])

  const setPortability = useCallback((portability: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      portability: portability,
      planBonus: portability || prevState.smartControl,
      planConfigs: prevState.virtualChip || portability || prevState.smartControl,
    }))
  }, [])  

  const setSmartControl = useCallback((smartControl: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      smartControl: smartControl,
      planBonus: prevState.portability || smartControl,
      planConfigs: prevState.virtualChip || prevState.portability || smartControl
    }))
  }, [])

  useEffect(() => {
    const param = location.pathname.split("/")[1]
    setCurrentPath(param)
  }, [location])

  useEffect(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      planClass: colorPlanClasses[prevState.planIndex],
      monthlyPrice: prevState.plans[prevState.planIndex]?.valor || prevState.monthlyPrice,
      annualPrice: ((prevState.plans[prevState.planIndex]?.valor || prevState.monthlyPrice) * 8) / 12,
    }))
    // eslint-disable-next-line
  }, [currentState.currentPlan])

  useEffect(() => {
    setCurrentState((prevState) => {
      const updateInternetString = (value: string) => {
        const match = value.match(/(\d+)\s*(GB|MB)/i)
        if (!match) return value
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, number, unit] = match
        const newNumber = parseInt(number) + 5
        return `${newNumber} ${unit}`
      }
  
      const originalPlans = prevState.plans.map((plan, index) => ({
        ...plan,
        plano: prevState.plans[index]?.internet_padrao || plan.plano,
      }))
  
      const newPlans = prevState.portability
        ? prevState.plans.map((plan) => ({
            ...plan,
            plano: updateInternetString(plan.plano),
          }))
        : originalPlans
  
      const newPlan = prevState.portability
        ? updateInternetString(prevState.currentPlan.plano)
        : prevState.plans[prevState.planIndex]?.internet_padrao || prevState.currentPlan.plano
  
      return {
        ...prevState,
        currentPlan: { ...prevState.currentPlan, plano: newPlan },
        plans: newPlans,
      }
    })
  }, [currentState.portability])

  useEffect(() => {
    async function fetchPlans() {
      if (currentState.plans.length > 0) return
  
      try {
        const plansArray = await requests.plansList()
  
        if (Array.isArray(plansArray)) {
          const adjustPlanInternet = (plan: Plan, portability: boolean) => {
            const match = plan.internet_padrao.match(/(\d+)\s*(GB|MB)/i)
            if (!match) return plan.plano
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, number, unit] = match
            const adjustedNumber = portability ? parseInt(number) + 5 : parseInt(number)
            return `${adjustedNumber} ${unit}`
          }
  
          const adjustedPlans = plansArray.map((plan) => ({
            ...plan,
            plano: adjustPlanInternet(plan, defaultPlansData.portability),
          }))
  
          const firstPlan = adjustedPlans[0] || defaultPlan
  
          const updatedCurrentPlan =
            currentState.currentPlan?.id === 0
              ? { ...firstPlan, plano: adjustPlanInternet(firstPlan, defaultPlansData.portability) }
              : { ...currentState.currentPlan, plano: adjustPlanInternet(currentState.currentPlan, defaultPlansData.portability) }
  
          setCurrentState((prevState) => ({
            ...prevState,
            plans: adjustedPlans,
            currentPlan: updatedCurrentPlan,
            planClass: colorPlanClasses[prevState.planIndex],
            monthlyPrice: adjustedPlans[0]?.valor || prevState.monthlyPrice,
            annualPrice: ((adjustedPlans[0]?.valor || prevState.monthlyPrice) * 8) / 12,
            loadingPlan: false,
          }))
        } else {
          console.error("Resposta inesperada da API:", plansArray)
        }
      } catch (error) {
        console.error("Erro ao buscar planos:", error)
      }
    }
  
    if (currentPath === "" || currentPath === "vip") {
      fetchPlans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])
  
  

  return (
    <PlanContext.Provider
      value={{
        ...currentState,
        updatePlanState,
        setvirtualChip,
        setPortability,
        setSmartControl
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}