import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"

export default function ChosenPlanChip({ name, variables }: ISettingsItem) {
  const { currentPlan, virtualChip, planIndex, monthlyPrice, annualPrice, chosenPlan } = usePlan()
  const { chipInfo: { chipPrice, shippingPrice } } = useInitReq()
  const [currentChipPrice, setCurrentChipPrice] = useState('0,00')
  const defaultPlanLabelArray = ['Start', 'Flexi', 'Mega', 'Ultra', 'Tour']
  const defaultChipTextArray = ['Pagamento Único', 'Entrega Digital']
  const { text } = variables || {}
  const planLabelArray = text || defaultPlanLabelArray
  const chipTextArray = defaultChipTextArray
  const currentChosenPlan = currentPlan.plano.replace(' ', '')
  const planLabel = planLabelArray[planIndex] || ''
  const chosenPlanLabel = chosenPlan === 'monthly' ? 'Pagamento mensal' : 'Pagamento à vista'

  useEffect(() => {
    const valor_chip = chipPrice ? parseFloat(chipPrice) : 0
    const valor_frete = shippingPrice ? parseFloat(shippingPrice) : 0
    const currentChipPrice = valor_chip + valor_frete
    setCurrentChipPrice(currentChipPrice.toFixed(2).replace('.', ','))
  }, [virtualChip, chipPrice, shippingPrice])

  const planPrice = () => {
    const currentPricePlan = chosenPlan === 'monthly' ? monthlyPrice : chosenPlan === 'annual' ? annualPrice * 12 : 0
    return (currentPricePlan).toFixed(2).replace('.', ',')
  }

  return (
    <Grid container item className={name ? `${name} chosen-plan-chip` : "chosen-plan-chip"} xs={12}>
      <Grid container item className="chosenPlanChip chosenPlan">
        <Grid item className="textArea">
          <h3>{currentChosenPlan} {planLabel}</h3>
          <p>{chosenPlanLabel}</p>
        </Grid>
        <Grid item className="priceArea">
          <h3>R$ {planPrice()}</h3>
        </Grid>
      </Grid>
      <Grid container item className="chosenPlanChip chosenChip">
        {
          !virtualChip ?
            <Grid container item className="physical chip">
              <Grid item className="textArea">
                <h3>Chip Físico</h3>
                <p>{chipTextArray[0]}</p>
              </Grid>
              <Grid item className="priceArea">
                <h3>R$ {currentChipPrice}</h3>
              </Grid>
            </Grid>
            :
            <Grid container item className="virtual chip">
              <Grid item className="textArea">
                <h3>Chip Virtual</h3>
                <p>{chipTextArray[1]}</p>
              </Grid>
              <Grid item className="priceArea">
                <h3>R$ 0,00</h3>
              </Grid>
            </Grid>
        }
      </Grid>
    </Grid>
  )
}