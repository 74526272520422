import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import SwitchDefault from "../../SwitchDefault"

export default function SelectSmartControl({ name, variables }: ISettingsItem) {
  const { smartControl, setSmartControl } = usePlan()
  const { text } = variables || {}

  const defaultTextArray = [
    'Contrato Inteligente',
    'Mesmo valor com mais internet',
    '+5GB GRÁTIS',
    'flex',
  ]

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const splitChoosePortabilityDetail = mergedArray[2].split(' ')

  const handleSwitchChange = () => {
    setSmartControl(!smartControl)
  }

  return (
    <Grid container item className={name ? `${name} select-smart-control` : "select-smart-control"} xs={12}>
      <Grid container item className="choosePortability" xs={12}>
        <Grid item className="choosePortabilitySwitch" style={{ display: mergedArray[3] }}>
          <SwitchDefault checked={smartControl} onChange={handleSwitchChange} />
        </Grid>
        <Grid item className="choosePortabilityText">
          <h3>{mergedArray[0]}</h3>
          <p>{mergedArray[1]}</p>
        </Grid>
        <Grid item className="choosePortabilityDetail">
          <p>{splitChoosePortabilityDetail[0]} <span>{splitChoosePortabilityDetail[1]}</span></p>
        </Grid>
      </Grid>
    </Grid>
  )
}