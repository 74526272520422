import Switch, { SwitchProps } from '@mui/material/Switch'

interface SwitchDefaultProps extends SwitchProps {
  checked?: boolean;
  onChange?: () => void;
}

export default function SwitchDefault({ checked, onChange, ...props }: SwitchDefaultProps) {
  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={checked}
      onChange={onChange}
      {...props}
    />
  );
}
