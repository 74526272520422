import { Grid, Skeleton } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import SwitchDefault from "../../SwitchDefault"
import { ReactComponent as RightArrow } from '../../../assets/icons/rightArrow.svg'

export default function SelectPlanDetails({ name, variables }: ISettingsItem) {
  const {
    monthlyPrice,
    annualPrice,
    updatePlanState,
    portability,
    setPortability,
    loadingPlan,
  } = usePlan()
  const { loadingChipReq } = useInitReq()
  const { text } = variables || {}

  const currentMonthlyPrice = monthlyPrice.toFixed(2).replace('.', ',')
  const currentAnnualPrice = annualPrice.toFixed(2).replace('.', ',')

  const defaultTextArray = [
    'Trazer meu número de outra operadora',
    'Trazer meu número de outra operadora',
    'Você está ganhando mais internet',
    'Você está perdendo internet',
    'PERDER 5GB',
    'GANHAR 5GB',
    'MAIS PROCURADO',
    'Renove todo mês sem fidelidade',
    'MAIS RENTÁVEL',
    'Compre 12 e pague por 8 meses',
    'flex', // choosePortability
    'flex', // switch
    'flex', // cards
    'none', // annual
    'none' // detail
  ]

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const navigate = useNavigate()
  const handleCardClick = (chosen: string) => {
    navigate('/configure-seu-plano')
    updatePlanState({ chosenPlan: chosen })
  }

  const handleSwitchChange = () => {
    setPortability(!portability)
  }

  return (
    <Grid container item className={name ? `${name} select-plan-details` : "select-plan-details"} xs={12}>
      <Grid container item className="choosePortability" xs={12} style={{ display: mergedArray[10] }}>
        <Grid item className="choosePortabilitySwitch" style={{ display: mergedArray[11] }}>
          {!loadingPlan ? (
            <SwitchDefault checked={portability} onChange={handleSwitchChange} />
          ) : (
            <Skeleton className="skltn-switch" sx={{ borderRadius: '20px' }} variant="rounded" width="36px" height="21px" />
          )}
        </Grid>
        <Grid item className="choosePortabilityText">
          <h3>{portability ? mergedArray[0] : mergedArray[1]}</h3>
          <p>{portability ? mergedArray[2] : mergedArray[3]}</p>
        </Grid>
        {mergedArray[14] === 'none' ? (
          !portability && (
            <Grid item className="choosePortabilityDetail">
              <p>{portability ? mergedArray[4] : mergedArray[5]}</p>
            </Grid>
          )
        ) : (
          <Grid item className="choosePortabilityDetail">
            <p>{portability ? mergedArray[4] : mergedArray[5]}</p>
          </Grid>
        )}
      </Grid>
      {
        !loadingPlan && !loadingChipReq ? (
          <Grid container item className="monthlyAnnualCards" sx={{ display: mergedArray[12] }}>
            <Grid container item className="monthlyAnnualCard monthly" onClick={() => handleCardClick('monthly')}>
              <Grid container item className="monthlyAnnualTextArea" xs={8}>
                <Grid container item className="monthlyAnnualTitle">
                  <h3>Mensal</h3>
                  <Grid item className="monthlyAnnualDetails monthlyDetails">{mergedArray[6]}</Grid>
                </Grid>
                <p>{mergedArray[7]}</p>
              </Grid>
              <Grid item className="monthlyAnnualPriceArea" xs={4}>
                <Grid item className="monthlyPriceArea">
                  <h3>R$ {currentMonthlyPrice}</h3>
                </Grid>
                <RightArrow />
              </Grid>
            </Grid>
            <Grid container item className="monthlyAnnualCard annual" onClick={() => handleCardClick('annual')} style={{ display: mergedArray[13] }}>
              <Grid container item className="monthlyAnnualTextArea" xs={8}>
                <Grid container item className="monthlyAnnualTitle">
                  <h3>Anual</h3>
                  <Grid item className="monthlyAnnualDetails annualDetails">{mergedArray[8]}</Grid>
                </Grid>
                <p>{mergedArray[9]}</p>
              </Grid>
              <Grid container item className="monthlyAnnualPriceArea" xs={4}>
                <Grid item className="annualPriceArea">
                  <h3 className="oldPrice">R$ {currentMonthlyPrice}</h3>
                  <h3>R$ {currentAnnualPrice}</h3>
                </Grid>
                <RightArrow />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container item className="monthlyAnnualCards" sx={{ display: mergedArray[12] }}>
            <Skeleton className="skltn-monthlyAnnualCard" variant="rounded" width="100%" />
            <Skeleton className="skltn-monthlyAnnualCard" variant="rounded" width="100%" style={{ display: mergedArray[13] }} />
          </Grid>
        )
      }
    </Grid>
  )
}