import { useEffect, useMemo } from "react"
import { Box, Grid, Skeleton, Typography } from "@mui/material"
import { useCategory } from "../../../Categories"
import { usePlan } from "../../../Plans"
import SliderComponent, { MarkProps } from "../../SliderComponent"
import { ISettingsItem } from "../../../Settings"

interface CtgrMarksProps {
  KIDS: MarkProps[]
  BUSINESS: MarkProps[]
  GAMER: MarkProps[]
  INFLUENCER: MarkProps[]
}

export default function SliderPlans({ name, variables }: ISettingsItem) {
  const { text = ['Quantidade de Internet'] } = variables || {}
  const { ctgrClass } = useCategory()
  const { plans, currentPlan, loadingPlan, currentSliderValue, updatePlanState } = usePlan()

  const marks = (category: string) => {
    const ctgrPlans = plans.filter(({ categoria }) => categoria === category)
    if (!ctgrPlans.length) return []

    return ctgrPlans.map(({ plano, id }, index) => {
      const numericValue = parseInt(plano.replace(/[^\d]/g, ""), 10)
      const planNameText = plano.replace(/\s+/g, "")
      const isLast = index === ctgrPlans.length - 1
      return {
        id,
        value: isLast ? 100 : numericValue * 2,
        label: `${planNameText} PLANO`,
      }
    })
  }

  const ctgrMarks: CtgrMarksProps = useMemo(() => ({
    KIDS: marks('KIDS'),
    BUSINESS: marks('BUSINESS'),
    GAMER: marks('GAMER'),
    INFLUENCER: marks('INFLUENCER')
    // eslint-disable-next-line
  }), [plans, marks])

  const currentCtgrMark = ctgrMarks[ctgrClass as keyof CtgrMarksProps]

  useEffect(() => {
    if (currentSliderValue === 0 && currentCtgrMark?.length > 0) {
      updatePlanState({ currentSliderValue: currentCtgrMark[0].value })
    }
    // eslint-disable-next-line
  }, [ctgrMarks, ctgrClass, currentSliderValue, currentCtgrMark])

  useEffect(() => {
    const markChoosen = (currentCtgrMark || []).find(({ value }) => value === currentSliderValue)
    
    if (markChoosen) {
      const currentChoosenPlan = plans.find(({ id }) => id === markChoosen.id)

      if (currentChoosenPlan) {
        const ctgrPlans = plans.filter(({ categoria }) => categoria === ctgrClass)
        const ctgrPlanIndex = ctgrPlans.findIndex(plan => plan.id === currentChoosenPlan.id)

        updatePlanState({ currentPlan: currentChoosenPlan, planIndex: ctgrPlanIndex, chosenPlan: "monthly" })
      }
    }
    // eslint-disable-next-line
  }, [currentSliderValue])

  return (
    <Grid item className={name ? `${name} slider-plans` : "slider-plans"} xs={12}>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: text[0] }} />
      <Grid className="select-plan-area">
        {!loadingPlan ? (
          <>
            <SliderComponent marks={currentCtgrMark || []} currentValue={currentSliderValue} setCurrentValue={updatePlanState} />
            <Box className="selected-plan-text">{currentPlan.plano}</Box>
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" className="skltn-slider-component" />
            <Skeleton variant="rectangular" className="skltn-selected-plan-text" />
          </>
        )}
      </Grid>
    </Grid>
  )
}
