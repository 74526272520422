import { Divider, Grid, Typography } from '@mui/material'
import { ISettingsItem } from '../../../Settings'
import { usePlan } from '../../../Plans'
import { ReactComponent as ActivatedPortability } from '../../../assets/icons/activatedPortability.svg'

export default function ActivatedBnfts({ name, variables }: ISettingsItem) {
  const { portability } = usePlan()
  const defaultTextArray = [
    'Benefícios Ativados',
    'Portabilidade Ativada',
    'Mais internet pelo mesmo valor',
    '+5GB Grátis',
  ]
  const { text } = variables || {}
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  return (
    portability && (
      <Grid container item className={name ? `${name} activated-bnfts` : "activated-bnfts"} xs={12}>
        <Typography className="actvBnftsTitle" variant="h2" dangerouslySetInnerHTML={{ __html: mergedArray[0] }} />
        <Grid container item className="actvPortability" xs={12}>
          <Grid item  className="actvBnftsIcon">
            <ActivatedPortability />
          </Grid>
          <Grid item className="actvBnftsText">
            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: mergedArray[1] }} />
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: mergedArray[2] }} />
          </Grid>
          <Grid item className="actvBnftsDetail">
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: mergedArray[3] }} />
          </Grid>
        </Grid>
        <Divider className="divider" />
      </Grid>
    )
  )
}