import axios, { AxiosResponse } from 'axios'
import { PlansContext } from '../Plans'
import { Category } from '../Categories'
import { StatesListProps, CitiesListProps } from '../components/page2/left/Steps/AdressInfo'
import { LogisticProps, UserConfigsProps } from '../InitReq'
import { CompaniesTelData } from '../Form'

class Requests {
  token: string | null = null
  contelApi: string = process.env.REACT_APP_CONTEL_API || ''

  // REQUISIÇÕES
  async tokenAuthentication(): Promise<any> {
    try {
      const app = window.location.hostname
      const accessKeys = JSON.parse(process.env.REACT_APP_ACCESS_KEY)
      const accessKey = accessKeys[app].chave_acesso_franquia ?
        {
          chave_acesso: accessKeys[app].chave_acesso,
          chave_acesso_franquia: accessKeys[app].chave_acesso_franquia
        }
        :
        {
          chave_acesso: accessKeys[app].chave_acesso,
        }

      const response: AxiosResponse<{ token?: string }> = await axios.post(
        `${this.contelApi}/auth/token`,
        JSON.stringify(accessKey),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      const responseData = response.data

      if (responseData?.token) {
        this.token = responseData.token
      } else {
        throw new Error('Token não foi recebido da API.')
      }
      return responseData
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(`Erro na busca do Token: ${error.message}`)
      } else {
        console.error(`Erro desconhecido: ${error}`)
      }
      throw new Error('Erro na busca do Token.')
    }
  }

  async requestApi<T, S>(
    endpoint: string,
    method: 'get' | 'post' = 'get',
    data?: T
  ): Promise<S> {
    if (!this.token) {
      await this.tokenAuthentication()
    }
 
    try {
      const response: AxiosResponse<S> = await axios({
        method,
        url: `${this.contelApi}${endpoint}`,
        data: data || null,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      })
      return response.data
    } catch (error: any) {
      console.error(`Erro na resposta da API: ${error.message}`)
      throw new Error(`Erro na resposta da API: ${error.message}`)
    }
  }

  // ACESSO INDICAÇÃO
  async accessIndication(id: string): Promise<void> {
    try {
      await this.requestApi<any, any>(`/indicacao/salvaAcesso/${id}`, 'post')
    } catch (error) {
      console.error('Erro na requisição accessIndication:', error)
      throw error
    }
  }

  // FUNÇÕES GET
  async categoriesList(): Promise<Category[]> {
    try {
      const response = await this.requestApi<any, any>('/planos-categorias')
      return response
    } catch (error) {
      console.error('Erro na requisição categoriesList:', error)
      throw error
    }
  }

  async plansList(): Promise<PlansContext> {
    try {
      const { data: plansArray } = await this.requestApi<any, any>('/planos')
      return plansArray
    } catch (error) {
      console.error('Erro na requisição plansList:', error)
      throw error
    }
  }

  async statesList(): Promise<StatesListProps[]> {
    try {
      const { data } = await this.requestApi<any, any>('/estados')
      return data
    } catch (error) {
      console.error('Erro na requisição statesList:', error)
      throw error
    }
  }

  async citiesList(selectedState: string): Promise<CitiesListProps[]> {
    try {
      const { data } = await this.requestApi<any, any>(`/cidades/${selectedState}`)
      return data
    } catch (error) {
      console.error('Erro na requisição citiesList:', error)
      throw error
    }
  }

  async logistic(): Promise<LogisticProps> {
    try {
      const { valor_chip, valor_frete, valor_chip_avulso, valor_frete_avulso } = await this.requestApi<any, any>('/logistica')
      return { valor_chip, valor_frete, valor_chip_avulso, valor_frete_avulso }
    } catch (error) {
      console.error('Erro na requisição logistic:', error)
      throw error
    }
  }

  async userConfigs({id, tipo}: {id: string | number, tipo: string}): Promise<UserConfigsProps> {
    try {
      const response = await this.requestApi<any, any>(
        `/detalhes-venda?id=${id}&tipo=${tipo}`,
        'get'
      )
      return response
    } catch (error) {
      console.error('Erro na requisição userConfig:', error)
      throw error
    }
  }

  async companiesTel(): Promise<CompaniesTelData> {
    try {
      const { retorno, data } = await this.requestApi<any, any>('/portabilidade/operadorasDisponiveis')
      return { retorno, data }
    } catch (error) {
      console.error('Erro na requisição companiesTel:', error)
      throw error
    }
  }

  // FUNÇÕES POST
  async cepValidator(cep: any) {
    try {
      const requestData = { cep }
      const response = await this.requestApi<any, any>(
        '/validar/cep',
        'post',
        JSON.stringify(requestData)
      )
      return response
    } catch (error) {
      console.error('Erro na requisição cepValidator:', error)
    }
  }

  async cpfValidator(): Promise<any> {
    try {
      const requestData = { cpf: '52309817848' }
      const { data, valido } = await this.requestApi<any, any>(
        '/validar/cpf',
        'post',
        JSON.stringify(requestData)
      )
      return { data, valido }
    } catch (error) {
      console.error('Erro na requisição cpfValidator:', error)
      console.error('CPF inválido')
      throw new Error('CPF inválido')
    }
  }

  async cnpjValidator(): Promise<any> {
    try {
      const requestData = { cnpj: '02.068.812/0001-02' }
      const { data, valido } = await this.requestApi<any, any>(
        '/validar/cnpj',
        'post',
        JSON.stringify(requestData)
      )
      return { data, valido }
    } catch (error) {
      console.error('Erro na requisição cnpjValidator:', error)
      console.error('CNPJ inválido')
      throw new Error('CNPJ inválido')
    }
  }

  async buyRegister(requestData: any) {
    try {
      const { retorno, link_pagamento, mensagem } = await this.requestApi<any, any>(
        '/compra/solicitar',
        'post',
        JSON.stringify(requestData)
      )
      return { retorno, link_pagamento, mensagem }
    } catch (error) {
      alert('Algo deu errado! Tente novamente mais tarde.')
      throw new Error('Erro na requisição buyRegister:', error as Error)
    }
  }

  async activationReq(requestData: any) {
    try {
      const { retorno, link_pagamento, mensagem } = await this.requestApi<any, any>(
        '/ativacao/solicitar',
        'post',
        JSON.stringify(requestData)
      )
      return { retorno, link_pagamento, mensagem }
    } catch (error) {
      alert('Algo deu errado! Tente novamente mais tarde.')
      throw new Error('Erro na requisição activationReq:', error as Error)
    }
  }

  async portability(requestData: any) {
    try {
      const { retorno, mensagem } = await this.requestApi<any, any>(
        '/portabilidade/pre-agendar',
        'post',
        JSON.stringify(requestData)
      )
      return { retorno, mensagem }
    } catch (error) {
      alert('Algo deu errado! Tente novamente mais tarde.')
      throw new Error('Erro na requisição portability:', error as Error)
    }
  }
}

const requests = new Requests()

export default requests
