import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"

export default function Slogan({ name, variables }: ISettingsItem) {
  const { text = ['Turbine seu celular com a maior velocidade'] } = variables || {}

  const words = text[0].split(' ')
  const firstWord = words[0]
  const lastWord = words[words.length - 1]
  const midWords = ` ${words.slice(1, -1).join(' ')} `

  return (
    <Grid item className={name ? `${name} slogan` : "slogan"}>
      <h3>
        <span>{firstWord}</span>
        {midWords}
        <span>{lastWord}</span>
      </h3>
    </Grid>
  )
}