import { Grid } from "@mui/material"
import ringsIcon from '../../../assets/icons/ringsIcon.svg'
import { ISettingsItem } from "../../../Settings"

export default function Details({ name, variables }: ISettingsItem) {
  const { image = [ringsIcon], text = ['Faça como mais de 123mil usuários', 'Temos o plano certo para você'] } = variables || {}

  return (
    <Grid container item className={name ? `${name} details` : "details"}>
      <img src={image[0]} alt="Ícone de detalhes" />
      <Grid item className="detailsTextArea">
        <h3>{text[0]}</h3>
        <p>{text[1]}</p>
      </Grid>
    </Grid>
  )
}