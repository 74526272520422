import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as SecondSummaryIcon } from '../../../assets/icons/secondSummaryIcon.svg'

export default function SecondSummary({ name, variables }: ISettingsItem) {
  const defaultTextArray = ['Resumo do seu pedido', 'Tudo que está incluso em seu pedido conosco']
  const { text = defaultTextArray } = variables || {}

  return (
    <Grid container item className={name ? `${name} second-summary` : "second-summary"} xs={12}>
      <Grid item className="titleArea" xs={12}>
        <Grid className="iconsArea">
          <SecondSummaryIcon />
        </Grid>
        <Grid className="textArea">
          <h3>{text[0]}</h3>
          <p>{text[1]}</p>
        </Grid>
      </Grid>
    </Grid>
  )
}