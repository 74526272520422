import { Grid, Skeleton } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from "../../../Plans"
import { useInitReq } from "../../../InitReq"

export default function PlanSummaryPg3({ name, variables }: ISettingsItem) {
  const { smartControl } = usePlan()
  const { currentUserConfigs, loadingUserConfigs } = useInitReq()
  const defaultInfoTextArray = [
    "Seu Plano Mensal",
    "Seu Plano Anual",
    "Veja quanto de internet mensal você tem",
    "+5GB CONTRATO",
    "+5GB PORTABILIDADE",
  ]
  const defaultPlanLabelArray = ["Start", "Flexi", "Mega", "Ultra", "Tour"]
  const { text } = variables || {}
  const planLabelArray = text || defaultPlanLabelArray
  const currentChosenPlan = currentUserConfigs?.plano?.replace(" ", "")
  const plans = ["6GB", "10Gb", "15GB", "23GB", "45GB"]
  const planIndex = plans.findIndex(plan => plan === currentChosenPlan)
  const planLabel = planIndex >= 0 ? planLabelArray[planIndex] : ""
  const portability = currentUserConfigs?.portabilidade

  return (
    <Grid
      container
      item
      className={name ? `${name} plan-summary-pg-3` : "plan-summary-pg-3"}
    >
      {loadingUserConfigs ? (
        <>
          <Skeleton className="skltn-1 chosenPlan" variant="text" />
          <Grid item className="planDetailsText" xs={12}>
            <Skeleton className="skltn-2 skltn-h3" variant="text" />
            <p>{defaultInfoTextArray[2]}</p>
          </Grid>
          <Grid container item className="planDetailsItems" xs={12}>
            <Skeleton className="planDetailsItem skltn-3" variant="rounded" />
          </Grid>
        </>
      ) : (
        <>
          <Grid item className="chosenPlan" xs={12}>
            {planLabel} {currentChosenPlan}
          </Grid>

          <Grid item className="planDetailsText" xs={12}>
            <h3>
              {currentUserConfigs?.periodo === "mensal"
                ? defaultInfoTextArray[0]
                : defaultInfoTextArray[1]}
            </h3>
            <p>{defaultInfoTextArray[2]}</p>
          </Grid>

          <Grid container item className="planDetailsItems" xs={12}>
            <Grid item className="planDetailsItem">
              {currentChosenPlan} PLANO
            </Grid>
            {smartControl && (
              <Grid item className="planDetailsItem">
                {defaultInfoTextArray[3]}
              </Grid>
            )}
            {portability && (
              <Grid item className="planDetailsItem">
                {defaultInfoTextArray[4]}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}
