import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CircularProgress, createTheme, Grid, ThemeProvider } from '@mui/material'
import { InitReqProvider } from './InitReq'
import { SettingsProvider } from './Settings'
import { CategoryProvider } from './Categories'
import { PlanProvider } from './Plans'
import { FormProvider } from './Form'
import requests from './api/client'
import Layout from './layouts'

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontFamily: "'Poppins', sans-serif",
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
    },
    body1: {
      fontFamily: "'Poppins', sans-serif",
    },
    body2: {
      fontFamily: "'Poppins', sans-serif",
    }
  }
})

function App() {
  const [loadingToken, setLoadingToken] = useState(true)

  useEffect(() => {
    async function fetchToken() {
      const { retorno } = await requests.tokenAuthentication()
      if (retorno) setLoadingToken(false)
    }
    fetchToken()
  },[])

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SettingsProvider>
          <FormProvider>
            {
              loadingToken ? (
                <Grid
                  container
                  sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}
                  >
                  <CircularProgress />
                </Grid>
              ) : (
                <InitReqProvider>
                  <CategoryProvider>
                    <PlanProvider>
                      <Layout />
                    </PlanProvider>
                  </CategoryProvider>
                </InitReqProvider>
              )
            }
          </FormProvider>
        </SettingsProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App